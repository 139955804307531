require('./bootstrap');

import flatpickr from "flatpickr";
import { Japanese } from "flatpickr/dist/l10n/ja.js";
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index';

flatpickr(".flatpickr", {
  "locale": Japanese,
  "dateFormat": "Y/m/d",
});

window._ = require('lodash');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');
    window.axios = require('axios');
    window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

    require('bootstrap');

    window.Croppie = require('croppie');

    window.moment = require('moment');
    require('moment-timezone');
    window.UUID = require('uuidjs');

require('jszip');
require('pdfmake');

require('datatables.net-bs5');
require('datatables.net-buttons-bs5');
require('datatables.net-buttons/js/buttons.colVis');
require('datatables.net-buttons/js/buttons.html5');
require('datatables.net-buttons/js/buttons.print');

/*
require('bootstrap-fileinput/js/plugins/piexif.min');
require('bootstrap-fileinput/js/plugins/sortable.min');
require('bootstrap-fileinput/js/fileinput');
require('bootstrap-fileinput/js/locales/ja');
require('bootstrap-fileinput/themes/fas/theme.min');
*/
} catch (e) {}
